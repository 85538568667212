import * as React from 'react';
import { FC, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
// @ts-ignore
import { ReactComponent as WayPointIcon } from '@/assets/icons/WayPointIcon.svg';
import { JSXMarker } from '@/components/Map/components/JSXMarker';
import { BoundaryLoad, getBoundaries } from '@/utils/map';
import { MapPoint } from '@/types/map.types';
import { GeoPathCurve } from '@/components/Map/components/GeoPathCurve';
import { AirPortSvg } from '@/components/Map/components/AirPortSvg';
import { lightGrey, secondary, secondGradientEnd, secondGradientStart, white } from '@/theme/palette';
import styled from 'styled-components';
import { DepartureInsightType } from '@/utils/visualization/constants';
import { WaypointAltAnnotation } from '@/utils/plotly/annotations/types';
import { Accordion } from '@/components/Accordion';
import { MapWrapper } from '../Map/components/MapWrapper';

interface DepartureViewProps {
  data: DepartureInsightType
}

const amberColor = '#FFBF00';

const getAltitude = ({
  minAlt, maxAlt,
}: {
  minAlt?: WaypointAltAnnotation,
  maxAlt?: WaypointAltAnnotation
}) => {
  const alts = [minAlt, maxAlt].find(value => value?.color === 'amber');
  const alt = alts || maxAlt;
  return {
    isOutNorm: alt?.color === 'amber',
    value: alt?.altMsl.toFixed(0),
    unit: alt?.altMslUnit,
    threshold: minAlt?.threshold?.toString() ?? '',
  };
};

const departureData = (data: DepartureInsightType) => ({
  waypoints: (data?.annotation ?? [])?.map(val => {
    const isOutOfNorm = Object.values(val ?? {}).map(i => i?.[1]?.color).some(item => item === 'amber');
    return ({
      name: val?.lateralDistFromWaypoint?.[1]?.waypointName,
      lat: val?.lateralDistFromWaypoint?.[1]?.waypointLat,
      lon: val?.lateralDistFromWaypoint?.[1]?.waypointLon,
      isOutNorm: isOutOfNorm,
      lateralDist: {
        isOutNorm: val?.lateralDistFromWaypoint?.[1]?.color === 'amber',
        value: val?.lateralDistFromWaypoint?.[1]?.threshold?.toFixed(2),
        unit: val?.lateralDistFromWaypoint?.[1]?.thresholdUnit,
      },
      airspeed: {
        isOutNorm: val?.transitionMaxCas[1].color === 'amber',
        value: val?.transitionMaxCas?.[1]?.cas.toFixed(0),
        unit: val?.transitionMaxCas?.[1]?.casUnit,
      },
      altitude: getAltitude({
        minAlt: val?.minAltAtWaypoint?.[1],
        maxAlt: val?.maxAltAtWaypoint?.[1],
      }),
      groundTrack: {
        isOutNorm: val?.transitionGndTrackDeviation?.[1]?.color === 'amber',
        value: val?.transitionGndTrackDeviation?.[1]?.gndTrackAtWaypoint.toFixed(2),
        unit: val?.transitionGndTrackDeviation?.[1]?.gndTrackAtWaypointUnit,
      },
    });
  }).filter(i => !!i.lat || !!i.lon),
  runway: {
    name: data?.departure?.runway?.name,
    lat: data?.departure?.runway?.latitude,
    lon: data?.departure?.runway?.longitude,
    true_heading: data?.departure?.runway?.trueHeading ?? 0,
  },
  sid: {
    name: data?.departure?.sid?.name,
    waypoints: data?.departure?.sid?.waypoints,
  },
});

export const DepartureView: FC<DepartureViewProps> = ({ data }) => {
  const departure = departureData(data);
  const center: MapPoint | undefined = !!departure.runway.lat || !!departure.runway.lon
    ? [departure.runway.lat!, departure.runway.lon!]
    : undefined;

  const points = departure.waypoints.map<MapPoint>(point => [point.lat, point.lon]);
  const pointsWithCenter = [
    ...(center ? [center] : []),
    ...points];

  const bounds = useMemo(() => getBoundaries({
    points: pointsWithCenter,
  }), [pointsWithCenter]);

  if (!bounds || points.length <= 0) return null;

  return (
    <div>
      <Title>
        {departure.sid.name}
      </Title>
      <MapWrapper height="500px" width="100%" minZoom={7}>
        <BoundaryLoad markerBounds={bounds} />
        {departure.waypoints.map(point => {
          if (!point.name) return null;
          return (
            <JSXMarker
              key={point.name}
              position={[point.lat, point.lon]}
              iconOptions={{
                className: 'jsx-marker',
                iconSize: [24, 12],
              }}
              tooltip={(
                <TooltipMarker>
                  <TooltipMarkerText $isOutNorm={point.airspeed.isOutNorm}>
                    Airspeed: {point.airspeed.value} {point.airspeed.unit}
                  </TooltipMarkerText>
                  <TooltipMarkerText $isOutNorm={point.altitude.isOutNorm}>
                    Altitude: {point.altitude.value} {point.altitude.unit}
                  </TooltipMarkerText>
                  <TooltipMarkerText $isOutNorm={point.groundTrack.isOutNorm}>
                    Ground Track: {point.groundTrack.value} {point.groundTrack.unit}
                  </TooltipMarkerText>
                  <TooltipMarkerText $isOutNorm={point.lateralDist.isOutNorm}>
                    Lateral Distance: {point.lateralDist.value} {point.lateralDist.unit}
                  </TooltipMarkerText>
                </TooltipMarker>
              )}
            >
              <InfoMarker>
                <ImagerWrapper $isOutNorm={point.isOutNorm}>
                  <WayPointIcon />
                </ImagerWrapper>
                <InfoMarkerText $isOutNorm={point.isOutNorm}>
                  {point.name}
                  <AltitudeText $isOutNorm={point.isOutNorm}>
                    {point.altitude.threshold}
                  </AltitudeText>
                </InfoMarkerText>
              </InfoMarker>
            </JSXMarker>
          );
        })}
        <AirPortSvg direction={departure.runway.true_heading} center={center} />
        <GeoPathCurve
          points={pointsWithCenter}
          gradient={{
            start: secondGradientStart.toString(),
            end: secondGradientEnd.toString(),
          }}
        />
      </MapWrapper>
      <Points>
        {departure.waypoints.map(point => (
          <Accordion
            key={point.name}
            title={(
              <AccordionTitle $isOutNorm={point.isOutNorm}>
                {point.name}
                <AltitudeText $isOutNorm={point.isOutNorm}>
                  {point.altitude.threshold}
                </AltitudeText>
              </AccordionTitle>
            )}
          >
            <PointWrapper>
              <Point $isOutNorm={point.airspeed.isOutNorm}>
                <PointTitle>
                  Airspeed
                </PointTitle>
                <PointValue>
                  {point.airspeed.value} {point.airspeed.unit}
                </PointValue>
              </Point>
              <Point $isOutNorm={point.altitude.isOutNorm}>
                <PointTitle>
                  Altitude
                </PointTitle>
                <PointValue>
                  {point.altitude.value} {point.altitude.unit}
                </PointValue>
              </Point>
              <Point $isOutNorm={point.groundTrack.isOutNorm}>
                <PointTitle>
                  Ground Track
                </PointTitle>
                <PointValue>
                  {point.groundTrack.value} {point.groundTrack.unit}
                </PointValue>
              </Point>
              <Point $isOutNorm={point.lateralDist.isOutNorm}>
                <PointTitle>
                  Lateral Distance
                </PointTitle>
                <PointValue>
                  {point.lateralDist.value} {point.lateralDist.unit}
                </PointValue>
              </Point>
            </PointWrapper>
          </Accordion>
        ))}
      </Points>
    </div>
  );
};

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
`;
const InfoMarker = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  gap: 4px;
`;
const Points = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 48px;
`;
const PointWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-block: 10px;
  padding-inline: 16px;
`;

const Point = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
  white-space: nowrap;
  overflow: hidden;
`;

const PointTitle = styled.div`
  border-bottom: 1px solid ${lightGrey.string()};
  padding-inline: 4px;
  color: inherit;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

const PointValue = styled.div`
  padding-inline: 4px;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;

`;

const AccordionTitle = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
  gap: 4px;
`;

const InfoMarkerText = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
  line-height: 1.2;
`;
const AltitudeText = styled.span<{ $isOutNorm?: boolean }>`
  text-align: center;
  color: ${props => (props.$isOutNorm ? amberColor : secondary.toString())};
  text-decoration: underline;
`;

const TooltipMarker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TooltipMarkerText = styled.div<{ $isOutNorm?: boolean }>`
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
`;

const ImagerWrapper = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    path {
      stroke: ${props => (props.$isOutNorm ? amberColor : white.toString())};
    }
  }
`;

export default DepartureView;
