import { MapPoint } from '@/types/map.types';
import { SVGOverlay, useMap } from 'react-leaflet';
import { FC, useEffect, useState } from 'react';
import L from 'leaflet';
import * as React from 'react';
import { lightestGrey, white } from '@/theme/palette';

type AirPortSvgProps = {
  center?: MapPoint,
  direction: number
}
const computeRotatedPoint = (cx:number, cy:number, dx:number, dy:number, angle:number) => {
  const cosAngle = Math.cos(angle);
  const sinAngle = Math.sin(angle);

  return [
    cx + dy * cosAngle - dx * sinAngle,
    cy + dy * sinAngle + dx * cosAngle,
  ];
};

export const AirPortSvg: FC<AirPortSvgProps> = ({ direction, center }) => {
  if (!center) return null;
  const map = useMap();
  const [config, setConfig] = useState<{ center: L.Point, rectPoints: number[][], radius: number } | null>(null);

  const updateConfig = () => {
    const mapCenter = map.latLngToContainerPoint(center);

    // 0.0252 degrees approximates 4200 meters
    const latLngRadius = L.latLng(center[0], center[1] + 0.0375);
    const pixelRadius = map.latLngToLayerPoint(center).distanceTo(map.latLngToLayerPoint(latLngRadius));

    const runwayAngle = (direction * Math.PI) / 180;
    const dx = pixelRadius / 2;
    const dy = pixelRadius / 20;

    setConfig({
      center: mapCenter,
      radius: pixelRadius,
      rectPoints: [
        computeRotatedPoint(mapCenter.x, mapCenter.y, dx, dy, runwayAngle),
        computeRotatedPoint(mapCenter.x, mapCenter.y, -dx, dy, runwayAngle),
        computeRotatedPoint(mapCenter.x, mapCenter.y, -dx, -dy, runwayAngle),
        computeRotatedPoint(mapCenter.x, mapCenter.y, dx, -dy, runwayAngle),
      ],
    });
  };

  useEffect(() => {
    if (!map) return undefined;
    updateConfig();

    map.on('zoomend', updateConfig);
    map.on('moveend', updateConfig);
    return () => {
      map.off('zoomend', updateConfig);
      map.off('moveend', updateConfig);
    };
  }, [map]);

  if (!config) return null;

  return (
    <SVGOverlay bounds={map.getBounds()}>
      <g
        style={{
          transformBox: 'fill-box',
          transformOrigin: '50% 50%',
        }}
      >
        <circle
          cx={config.center.x}
          cy={config.center.y}
          r={config.radius}
          stroke="transparent"
          fill={lightestGrey.toString()}
          style={{ opacity: '0.25' }}
        />
        <polygon
          points={config.rectPoints.map(d => d.join(',')).join(' ')}
          fill={white.toString()}
          style={{
            transformBox: 'fill-box',
            transformOrigin: '50% 50%',
          }}
        />
      </g>
    </SVGOverlay>
  );
};

export default AirPortSvg;
