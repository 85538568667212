import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { SVGOverlay, useMap } from 'react-leaflet';
import { generateLine, getBoundaries, getLayerPoints } from '@/utils/map';
import { MapPoint } from '@/types/map.types';

interface GeoPathCurveProps {
    points: MapPoint[];
    gradient:{
        start:string,
        end:string,
    }
}

export const GeoPathCurve: React.FC<GeoPathCurveProps> = ({ points, gradient }) => {
  const map = useMap();
  const bounds = getBoundaries({ points })!;
  const [path, setPath] = useState<string | null>(null);
  const startBound = map.latLngToLayerPoint(bounds[0]).x;
  const endBound = map.latLngToLayerPoint(bounds[1]).y;

  const updatePath = () => {
    const lineGenerator = generateLine()
      .curve(d3.curveCatmullRom.alpha(1));

    const layerPoints = getLayerPoints({ points, map });

    setPath(lineGenerator(layerPoints));
  };

  useEffect(() => {
    if (!map) return undefined;
    updatePath();

    map.on('zoomend', updatePath);
    map.on('moveend', updatePath);
    return () => {
      map.off('zoomend', updatePath);
      map.off('moveend', updatePath);
    };
  }, [map, points]);

  if (!path) return null;

  return (
    <SVGOverlay bounds={bounds}>
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              stopColor: gradient.start,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: gradient.end,
            }}
          />
        </linearGradient>
      </defs>
      <g transform={`translate(${-startBound},${-endBound})`}>
        <path d={path} fill="none" stroke="url(#grad1)" strokeWidth={2} />
      </g>
    </SVGOverlay>
  );
};

export default GeoPathCurve;
